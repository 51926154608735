import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;
    private intervalState = null;
    private windowHandle = null;
    private windowHandleSteam = null;
    private intervalId = null;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('access_token') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated || this.windowHandle)
        {
            return throwError('User is already logged in.');
        }

        this.resetWindowState();
        this.windowHandle = this.createOauthWindow('https://api.ark.wtf/auth/discord', 'Login with Discord');
        this.intervalState = 1;

        return new Observable((observer) => {
            this.intervalId = window.setInterval(() => {              
                if (this.intervalState < 0) {
                    this.windowHandle.close();
                    this.resetWindowState();
                } else {
                    try {
                        if (window['handleHandlePopupClosed']) {
                            this._authenticated = true;
                            this._userService.user = window['handleHandlePopupClosed'].user;
                            observer.next(window['handleHandlePopupResult']);
                            observer.complete();
                            this.resetWindowState();
                            location.reload();
                            return {unsubscribe() {}};
                        } else {
                            console.log('Window is still open.');
                        }
                    } catch (e) {
                        console.log(e);
                        return;
                    }
                }
            }, 500);
        });
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        console.log('sign out');

        this._httpClient.get('https://api.ark.wtf/auth/logout', { withCredentials: true }).subscribe(
            (response: any) => {
                console.log(response);
                this._authenticated = false;
                this._userService.user = null;
                this.resetWindowState();
            },
            (err) => {
				console.log("Error:", err);
			}
        );

        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        return this._httpClient.post('https://api.ark.wtf/auth', {}, { withCredentials: true }).pipe(
            catchError(() => { return of(false) }),
            switchMap((response: any) => {
                if (response === true || response === 'true') {
                    this._authenticated = true;
                    return this._httpClient.get('https://api.ark.wtf/me', { withCredentials: true }).pipe(
                        catchError(() => { return of(false) }),
                        switchMap((response: any) => {
                            this._userService.user = response;
                            return of(true);
                        })
                    );
                }
                return of(false);
            })
        );
    }

    /**
     * Sign in with Steam
     *
     * @param credentials
     */
    signInSteam(): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this.windowHandleSteam)
        {
            return throwError('User is already logged in.');
        }

        this.resetWindowState();
        this.windowHandleSteam = this.createOauthWindow('https://api.ark.wtf/auth/steam', 'Link to Steam');
        this.intervalState = 1;

        return new Observable((observer) => {
            this.intervalId = window.setInterval(() => {              
                if (this.intervalState < 0) {
                    this.windowHandleSteam.close();
                    this.resetWindowState();
                } else {
                    try {
                        if (window['handleHandlePopupClosed']) {
                            this._userService.steamUser = window['handleHandlePopupClosed'].steam;
                            console.log(this._userService.steamUser);
                            observer.next(window['handleHandlePopupResult']);
                            observer.complete();
                            this.resetWindowState();
                            return {unsubscribe() {}};
                        } else {
                            console.log('Window is still open.');
                        }
                    } catch (e) {
                        console.log(e);
                        return;
                    }
                }
            }, 500);
        });
    }

    /**
     * Sign in with Steam
     *
     * @param credentials
     */
    unlinkSteam(): Observable<any>
    {
        this._httpClient.post('https://api.ark.wtf/me/steam/unlink', {}, { withCredentials: true }).subscribe(
            (response: any) => {
                console.log(response);
            },
            (err) => {
				console.log("Error:", err);
			}
        );

        return of(true);
    }

    createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0) {
        if (url == null) {
         return null;
        }
        const options = `width=${width},height=${height},left=${left},top=${top}`;
        return window.open(url, name, options);
    }
    resetWindowState() {
        if (this.intervalId) clearInterval(this.intervalId);
        document.domain = 'ark.wtf';
        window['handleHandlePopupClosed'] = false;
        window['handleHandlePopupResult'] = null;
        this.intervalState = null;
        this.intervalId = null;
        this.windowHandle = null;
    }
}
