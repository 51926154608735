import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'app/core/user/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private _user: User = null;
    private _userSubject: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _steamUser: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user = value;
        this._userSubject.next(value);
        console.log(this._userSubject.asObservable())
    }

    get user(): User
    {
        return this._user;
    }

    get user$(): Observable<User>
    {
        return this._userSubject.asObservable();
    }

    set steamUser(value: User)
    {
        // Store the value
        this._steamUser.next(value);
    }

    get steamUser$(): Observable<User>
    {
        return this._steamUser.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('https://api.ark.wtf/me', {user}, { withCredentials: true }).pipe(
            map((response) => {
                // Execute the observable
                this._userSubject.next(response);
            })
        );
    }

    refresh(): void
    {
        this._httpClient.get('https://api.ark.wtf/me', { withCredentials: true }).subscribe(
            (response: User) => {
                // Execute the observable
                this._userSubject.next(response);
            },
            (err) => {
				console.log("Error:", err);
			}
        );
    }
}
